import axios from "axios";

const defaultHeader = {
  "Content-Type": "application/json",
  "X-XSS-Protection": "1; mode=block",
  "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
  "X-Content-Type-Options": "nosniff",
  "Cache-control": "no-store, no-cache",
};
const config = {
  baseURL: "https://attestation-hub-dev.azurewebsites.net/api/api",
  headers: defaultHeader,
};
const attestationAxios = axios.create(config);

export { attestationAxios };
